
body{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}
.logos{
    font-size: 30px;
}
.company_logo{
    width: 180px;
    height: 45px;
    margin-top: -5px;
}
.navbar_box{
    width: 100%; 
    background-color: #ecececc1;
    /* background-color: #236fe088; */
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    /* border-bottom: 1px solid rgb(234, 234, 239); */
}
.navbar_box>div{
    display: flex;
    margin: auto;
    justify-content: space-between;
    width: 80%;
    padding: 25px 25px;
}
.navbar_box>div>div{
    display: flex;
    gap: 20px;
}
.navbar_CallUs_btn{
    display: flex;
    padding-bottom: 0px;
    gap: 10px;
    padding-left: 25px;
    border-left: 1px solid black;
    margin: 0 !important;
    height: 30px;
}
.navbar_CallUs_btn>div:nth-child(2){
    margin-top: -10px;
    color: black;
}