body{
    background-color: rgb(42, 42, 90);
}
.filter_sort_div{
    width: 80%;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    margin: auto;
    display: flex;
    gap: 15px;
    padding: 20px 30px;
    background: rgb(247, 247, 247);
    padding: 20px;
    border-radius: 10px;
    /* border: 2px solid rgb(234, 234, 239); */
    margin-top: 50px;
}
.filter_sort_div>div{
    width: 20%;
    height: 65px;
    background-color: rgb(255, 255, 255);
    border: 2px solid #b3b3b3;
    border-radius: 4px;
}
.drawer_line{
    width: 100%;
    border-bottom: 1px solid #b3b3b3;
}
.sort_container{
    display: flex;
    justify-content: space-between;
    padding:17px 16px;
}
.small_text{
    color: rgb(76, 76, 76);
    font-size: 12px;
}
.bold_text{
    font-size: 16px;
    font-weight: 700;
}
.drawer_filters>div{
    display: flex;
    width: 100%;
    justify-content: space-between;
}
.drawer_headings{
    padding: 15px 0px;
    font-size: 18px;
    font-weight:700;
}
.drawer_amount_input{
    padding: 10px;
    border: 2px solid black;
    font-size: 18px;
    font-weight: 600;
}
.grid_radio{
    display: flex;
    flex-direction: column;
    font-size: 18px;
    font-weight: 500;
}
.home{
    background-image: url("../Assets/rm222batch2-mind-03.jpg");
    height: 100vh;
    background-size: 100% 100%;
}
.home>div{
    overflow-y: scroll;
    height: 100%;
}    
.navbar_container{
}