.flex_loan_card{
    display: flex;
}
.loan_card_container{
    width: 100%;
    margin: auto;
display: flex;
justify-content: space-between;
    background-color: white;
    /* border: 1px solid teal; */
    border-radius: 20px;
    padding: 20px;
    margin-top: 40px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}
.loan_heading{
    font-size: 20px;
    font-weight: 700;
}
.flex_loan_card2{
    display: grid;
    grid-template-columns: repeat(3,1fr);
    justify-items: center;
    align-items: center;
    padding-left: 20px;
    text-align:center;
    border-left: 1px solid rgb(216, 216, 216);
}
.flex_loan_card2_div{
    background-color: #0058dbdc;
    color: white;
    height: 100px;
    padding: 10px;
    margin-top: 10px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    border-radius: 10px;
}
.flex_loan_card2_div:hover{
    background-color: white;
    color: black;
}